<template>
  <!--
    A form to input the information for a new OpeningHours-object to be created and saved.
  -->
  <div class="startFinishConfigOpeningHoursFormAdd">
    <LoadingPlaceholder v-if="loading" />
    <template v-else>
      <template v-if="configData && openingHours">
        <h4 class="m-0 p-0">
          {{ $t("openingHours") }}
        </h4>
        <hr class="p-0 m-0 mb-3">
        <label>{{ $t("openingTime") }}</label>
        <input
          v-model="openingHours.openTime"
          :class="[
            'form-control',
            { 'is-invalid': errors.has(`OpeningHours[${currentId}].OpenTime`) },
          ]"
          type="time"
        >
        <span
          v-show="errors.has(`OpeningHours[${currentId}].OpenTime`)"
          class="badge badge-danger"
        >{{ errors.first(`OpeningHours[${currentId}].OpenTime`) }}</span>
        <br>
        <label class="mt-3">{{ $t("closingTime") }}</label>
        <input
          v-model="openingHours.closeTime"
          :class="[
            'form-control',
            {
              'is-invalid': errors.has(`OpeningHours[${currentId}].CloseTime`),
            },
          ]"
          type="time"
        >
        <span
          v-show="errors.has(`OpeningHours[${currentId}].CloseTime`)"
          class="badge badge-danger"
        >{{ errors.first(`OpeningHours[${currentId}].CloseTime`) }}</span>
        <span
          v-show="errors.has(`openingHours`)"
          class="badge badge-danger"
        >{{
          errors.first(`openingHours`)
        }}</span>
        <label class="mt-5">{{ $t("days") }}</label>
        <Multiselect
          id="selectConf"
          v-model="userWeekdays"
          placeholder="Select dates"
          :options="weekdays"
          select-label=""
          :multiple="true"
          :searchable="true"
          :close-on-select="true"
          label="name"
          track-by="id"
          class="mb-4"
        />
        <button
          class="btn btn-primary mt-3 float-right"
          @click="setConfig()"
        >
          <font-awesome-icon
            class="mr-2"
            :icon="updateButtonIcon"
            style="vertical-align: middle"
          />{{ updateButtonText }}
        </button>
      </template>
      <template v-else>
        {{ $t("noDataAvailable") }}
      </template>
    </template>
  </div>
</template>

<script>
import { errorMixin } from "@/mixins/errorMixin.js";

export default {
  name: "StartFinishConfigOpeningHoursFormAdd",
  components: {
    Multiselect: () => import("vue-multiselect"),
  },
  mixins: [errorMixin],
  props: {
    installationId: {
      type: String,
      required: true,
    },
    currentId: {
      type: Number,
      required: true,
    },
    laneNumber: {
      type: String,
      required: false,
      default() {
        return null;
      },
    },
  },
  data() {
    return {
      openingHours: null,
      configData: null,
      loading: true,
      updateButtonText: this.$t("update"),
      updateButtonIcon: "upload",
      weekdays: [
        { id: 1, name: "MON" },
        { id: 2, name: "TUE" },
        { id: 3, name: "WED" },
        { id: 4, name: "THU" },
        { id: 5, name: "FRI" },
        { id: 6, name: "SAT" },
        { id: 0, name: "SUN" },
      ],
      userWeekdays: [
        { id: 1, name: "MON" },
        { id: 2, name: "TUE" },
        { id: 3, name: "WED" },
        { id: 4, name: "THU" },
        { id: 5, name: "FRI" },
        { id: 6, name: "SAT" },
        { id: 0, name: "SUN" },
      ],
    };
  },
  created() {
    this.getConfig();
  },
  methods: {
    getConfig() {
      let url = `/Config/GetStartFinishConfig?installationId=${this.installationId}`;
      if (this.laneNumber) {
        url += `&laneNumber=${this.laneNumber}`;
      }
      this.axios
        .get(url)
        .then((response) => {
          this.error_clear();
          this.configData = response.data;
        })
        .finally(() => {
          if (this.configData.openingHours.length >= this.currentId) {
            this.openingHours = this.configData.openingHours[this.currentId];
            this.userWeekdays = this.weekdays.filter((x) =>
              this.openingHours.weekdays.includes(x.id)
            );
            this.loading = false;
          } else {
            this.getDefaultOpeningHours();
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getDefaultOpeningHours() {
      this.axios
        .get(`/Config/GetDefaultOpeningHours`)
        .then((response) => {
          this.error_clear();
          this.openingHours = response.data;
          this.userWeekdays = this.weekdays.filter((x) =>
            this.openingHours.weekdays.includes(x.id)
          );
          this.updateButtonText = this.$t("add");
          this.updateButtonIcon = "plus";
          this.loading = false;
        })
        .catch((error) => {
          this.$snotify.error(
            this.$t("startFinishConfigOpeningHoursFormAdd.notLoaded")
          );
          this.loading = false;
          this.error_clear();
          this.error_validate(error);
        });
    },
    setConfig() {
      let url = `/Config/AddNewOpeningHours?installationId=${this.installationId}&openingHoursIndex=${this.currentId}`;
      if (this.laneNumber) {
        url += `&laneNumber=${this.laneNumber}`;
      }
      this.openingHours.weekdays = this.userWeekdays.map((x) => x.id);
      this.axios
        .post(url, this.openingHours)
        .then((response) => {
          if (response.status == 204) {
            this.$emit("hide");
            this.$emit("setReloadButton", true);
            this.$snotify.error(this.$t("configReload.failedReload"));
          } else {
            this.$emit("setReloadButton", false);
            if (response.status != 200) {
              return;
            }
          }

          this.$snotify.success(
            this.$t("startFinishConfigOpeningHoursFormAdd.successfullySaved")
          );
          this.$emit("getConfig");
          this.error_clear();
        })
        .catch((error) => {
          this.$snotify.error(
            this.$t("startFinishConfigOpeningHoursFormAdd.notSaved")
          );
          this.error_clear();
          this.error_validate(error);
        });
    },
  },
};
</script>

<style>
.is-invalid,
.is-invalid * {
  color: red;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
